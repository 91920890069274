<template>
  <div>
    <a-select
      :value="selectedValue"
      showSearch
      :filterOption="filterOption"
      style="width: 100%"
      allowClear
      @change="changeValue"
    >
      <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id" :item="item">
        <div>{{ item.number }}</div>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { productionOrderOption } from "@/api/option";

export default {
  props: ["value", "type"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      selectedValue: undefined,
      dataItems: [],
    };
  },
  methods: {
    changeValue(value) {
      this.selectedValue = value;
      this.$emit("change", value);
    },
    filterOption(input, option) {
      const item = option.data.attrs.item;
      if (item.number.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.selectedValue = this.value;
    }
    productionOrderOption({ page_size: 999999 }).then((data) => (this.dataItems = data.results));
  },
};
</script>

<style scoped></style>
